import { Box, Stack } from '@mui/material';

export function CookieConsent() {
    return <Box></Box>;
    // return (
    //     <Popover
    //         id={id}
    //         open={open}
    //         anchorEl={anchorEl}
    //         onClose={handleClose}
    //         anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'left',
    //         }}
    //     ></Popover>
    // );
}
